import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import addNoteIcon from 'Assets/NoteModule/plusNew.svg';
import micIcon from 'Assets/NoteModule/mic.svg';
import note_pdf from 'Assets/NoteModule/note_pdf.png';
import note_doc from 'Assets/NoteModule/note_doc.png';
import note_csv from 'Assets/NoteModule/note_csv.png';
import note_ppt from 'Assets/NoteModule/note_ppt.png';
import note_xls from 'Assets/NoteModule/note_xls.png';
import back from 'Assets/CommonComponent/back.png';
import playIcon from 'Assets/NoteModule/play.svg';
import closeIcon from 'Assets/NoteModule/close.svg';
import tickIcon from 'Assets/NoteModule/tick.svg';
import warningIcon from "Assets/NoteModule/warning-icon.png";
import { getStudentGradebookNullRequest } from 'Actions/GradebookAction/studentGradebookAction'
import UpgradePopUp from '../../../upgradePopUp/upgradePopUp'
import { getNotesImage, getRosterImage } from "Config/commonFirebaseImage";
import IframeGoogleDoc from './../IframeGoogleDoc'
import { createNotification } from 'Config/notificationtoast';
import { storage } from "../../../../firebase/firebase";
import { FirebseStroageFolder } from 'Config/Constant';
import SelectSearch from 'react-select-search';
import {
    getStudentNote,
    deleteStudentNote,
    getStudentClasses,
    deleteGroupNoteFromSingleStudent,
    setStudentArtifact,
    addStudentMiscellaneous,
    editStudentMiscellaneous,
    deleteStudentMiscellaneous,
    setMediaAttachmentArtifact
} from 'Actions/NoteAction/studentNoteAction';
import { Item } from "./components";
import Carousel from "./Carousel";
import loaderImag from 'Assets/ProfileModule/PepperyMedium.gif'
import StudentNoteTimelineComponent from '../StudentNoteTimelineComponent/StudentNoteTimeline';
import StudentNoteExpandedTimelineComponent from '../StudentNoteExpandedTimelineComponent/StudentNoteExpandedTimeline';
import * as routes from "Router/RoutesURL";
import './StudentNoteDashboard.scss';
import { fuzzySearch } from "react-select-search";
import search from 'Assets/AttendanceModule/search.png';
import InfiniteScroll from 'react-infinite-scroller';
import DivLoader from 'Components/LoadingComponent/DivLoader';
class StudentNoteDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // onLoad Param..
            show: false,
            ConfirmationShow: false,
            ConfirmationType: '',
            noteContainMedia: false,
            whatToDelete: [],
            videoAttachment: [],
            audioAttachment: [],
            docAttachment: [],
            StudentAllNoteDetails: [],
            fetchAttachmentLoader: false,
            showPopUp: false,
            Userprofile: "",
            userProfileName: '',
            academicYear: '',
            classIdForGradebook: '',
            studentId: '',
            studentInfo: [],
            PageFrom: '',
            totalNoteCount: '',
            onSecondTime: false,
            onCount: 0,

            // API Param...
            page_no: 1,
            isMoreLoad: false,
            filter_by: 'both',
            class_id: "",
            EnrolledData: '',

            // Get Notes Param..
            class_core_standard: [],
            NotesData: [],
            student_details: [],
            student_enrolled_classes: [],

            // Add Miscellaneous para..
            misc_note: '',
            isSubmit: false,

            // View  Attachement para..
            showAttachement: false,
            showAttachementURL: '',
            showAttachementName: '',
            showAttachementType: '',
            showAttachementId: '',
            isMediaArtified: "no",
            selectedMediaIndex: 0,

            showDocAttachement: false,
            showDocAttachementURL: '',
            showDocAttachementName: '',
            showDocAttachementType: '',
            showDocAttachementId: '',
            isDocMediaArtified: "no",
            selectedDocIndex: 0,
            pageBYClass: false,
            rosterClassId: '',
            classPermission: '',
            tabView: 'timeLine',
            classListData: [],
            isSelected: true,
            selectedClassName: '',
            selectedClassName2: '',
            newClassListData: [],
            showDeleteGroupNoteModal: false,
            groupNoteDeleteId: 0,

            // -----SCROLL LOAD MORE-------


            numBoxes: 0,
            academicYear: "",
            academicYearId: "",
            classIdForGradebook: "",
            loadMoreCount: 1,
            filter: "",
            nameFilter: "",
            StudentList: [],
            StudentOverAllCount: [],
            isLoading: false,
            clientHeight: 0,
            hasScrolledToBottom: false,
            loadMoreCountStatus: false
        };
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.loadMore = this.loadMore.bind(this);
        this.DataRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        sessionStorage.setItem("backpage2" , "smthelse")
        const boxWidth = 150; // width of each box in pixels
        const boxHeight = 90; // height of each box in pixels
        const divWidth = this.DataRef?.current?.offsetWidth;
        const divHeight = this.DataRef?.current?.offsetHeight;

        const numBoxesWidth = Math.floor(divWidth / boxWidth);
        const numBoxesHeight = Math.floor(divHeight / boxHeight);
        const numBoxes = numBoxesWidth * numBoxesHeight;
        this.setState({ numBoxes });

        this.setState({
            onSecondTime: false
        })
        let blank_Array = []
        this.setState({
            class_core_standard: blank_Array,
            NotesData: blank_Array,
            student_details: blank_Array,
            student_enrolled_classes: blank_Array,
        })

    }
    componentDidUpdate(prevProps, prevState) {
        const { numBoxes } = this.state;
        console.log('this.state.class_id :>> ', this.state.class_id);  
        let classId;
        setTimeout(() => {
             classId = this.state.class_id;
        }, 5000);
       
        if (this.state.numBoxes !== prevState.numBoxes && this.state.numBoxes !== "NaN" && this.state.numBoxes > 0) {
            console.log("class_id getting here")
            //   const { data = "" } = this.history.location.state;
             
            const { location = {} } = this.props
            const { state = {} } = location;
            const { studentInfo = '' } = state;
            const { class_id, filter_by, page_no } = this.state;
            //   if (data && data.class_data && data.class_data.length > 0) {
                //     classId = data.class_data[0].cc_class_id;
                //   }
            console.log('this.state.class_id :>>55555 ', this.state.class_id);   
            setTimeout(() => {
                console.log('this.state.class_id222222 :>> ', this.state.class_id); 
                console.log('class_id:>> ', classId);
            }, 6000);
            const value = {
                "class_id": sessionStorage?.getItem('classIDForNotes') > 0 ? sessionStorage?.getItem('classIDForNotes') : class_id,
                "student_id": studentInfo?.cc_student_id,
                "filter_by": filter_by,
                "page_no": page_no,
                "limit": this.state.numBoxes,
                "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
            }
            console.log(' sessionStorage?.getItem()FFFFFFFFFFF :>> ',  sessionStorage?.getItem('classIDForNotes'));
            console.log('class_id FFFFFFFFFFF  :>> ', class_id);
            console.log('value FFFFF :>> ', value);
            // this.props.getStudentNote(value)
        }
    }

    handleScroll() {    
        const { scrollTop, clientHeight, scrollHeight } = this.DataRef.current;
        const { StudentList, StudentOverAllCount, numBoxes, loadMoreCount } = this.state;
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;
        const { class_id, filter_by, page_no, notesData } = this.state;
        this.setState({ clientHeight });

        if (this.state.NotesData && this.state.NotesData.length !== this.state.totalNoteCount) {

            if (scrollTop + clientHeight === scrollHeight) {

                let pageNoData = loadMoreCount;
                pageNoData++;
                this.setState({
                    loadMoreCount: pageNoData,
                    loadMoreCountStatus: true
                });


                const value = {
                    "class_id": sessionStorage?.getItem('classIDForNotes') > 0 ? sessionStorage?.getItem('classIDForNotes') : class_id,
                    "student_id": studentInfo?.cc_student_id,
                    "filter_by": filter_by,
                    "page_no": page_no,
                    "limit": this.state.numBoxes,
                    "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
                }
                console.log(' sessionStorage?.getItem()FFFFFFFFFFF :>> ',  sessionStorage?.getItem('classIDForNotes'));
                console.log('class_id FFFFFFFFFFF  :>> ', class_id);
                this.props.getStudentNote(value)
            }
        }

    }

    // ---------BackTO-------
    BackTOHome = () => {

        if (this.props.history.location.state.BackPage === "Academic Year") {
            this.props.history.replace("/home/academic")
        }
        // else if(this.props.history.location.state.BackPage == "Class Roster"){
        //     this.props.history.replace("/home/class")

        // }
        else {
            this.props.history.goBack()
        }
    }
    // ---------BackTO-------


    // ---------Get Roster Notes-------
    getRosterNotes() {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;
        const { class_id, filter_by, page_no } = this.state;
        const data = {
            "class_id": sessionStorage?.getItem('classIDForNotes') > 0 ? sessionStorage?.getItem('classIDForNotes') : class_id,
            "student_id": studentInfo?.cc_student_id,
            "filter_by": filter_by,
            "page_no": page_no,
            "limit": this.state.numBoxes,
            "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
        }
        console.log(' sessionStorage?.getItem()FFFFFFFFFFF :>> ',  sessionStorage?.getItem('classIDForNotes'));
        console.log('class_id FFFFFFFFFFF  :>> ', class_id);
        this.props.getStudentNote(data)
    }
    // ---------Get Roster Notes-------


    // ---------Delete Student Note --------
    deleteStudentNote = (value) => {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;
        const { class_id, filter_by } = this.state;

        if(value?.data?.cc_note_batch_ids && value?.data?.cc_note_batch_ids.length > 0 && value?.data?.cc_note_batch_ids.includes(',')) {
            console.log('group note')
            this.setState({
                showDeleteGroupNoteModal: true,
                groupNoteDeleteId: value?.data?.cc_note_id
            })
        } else {

        const data = {
            "class_id": class_id,
            "student_id": studentInfo?.cc_student_id,
            "filter_by": filter_by,
            "page_no": 1,
            "note_id": value.data.cc_note_id,
            "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
        }
        if (value?.data?.note_attachment?.length > 0) {
            this.setState({
                noteContainMedia: true
            })
        }
        this.setState({
            ConfirmationShow: true,
            ConfirmationType: "Note",
            whatToDelete: data,
            page_no: 1
        })
    }
    }
    // ---------Delete Student Note --------

    // ---------componentDidMount-------
    // componentDidMount() {
    //     this.setState({
    //         onSecondTime: false
    //     })
    //     let blank_Array = []
    //     this.setState({
    //         class_core_standard: blank_Array,
    //         NotesData: blank_Array,
    //         student_details: blank_Array,
    //         student_enrolled_classes: blank_Array,
    //     })
    // }
    // ---------componentDidMount-------

    // ---------UNSAFE_componentWillMount-------
    UNSAFE_componentWillMount() {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '', academicYear = '', From = '', pageBYClass = '', rosterClassId = '', classId = '', classPermission = '', classListData = '', selectedClassName = '', selectedClassName2 = '' } = state;
        let blank_Array = []

        this.setState({
            PageFrom: From,
            studentInfo: studentInfo,
            classIdForGradebook: classId,
            academicYear: academicYear,
            class_core_standard: blank_Array,
            NotesData: blank_Array,
            student_details: blank_Array,
            student_enrolled_classes: blank_Array,
            pageBYClass: pageBYClass,
            rosterClassId: sessionStorage?.getItem('classIDForNotes') > 0 ? sessionStorage?.getItem('classIDForNotes') : rosterClassId,
            classPermission: classPermission,
            classListData: classListData,
            selectedClassName: selectedClassName,
            selectedClassName2: selectedClassName2
        })

        const { class_id, filter_by, page_no } = this.state;
        const data = {
            "student_id": studentInfo?.cc_student_id,
            "page_no": page_no,
            "class_id": class_id,
            "filter_by": filter_by,
            "pageBYClass": pageBYClass,
            "rosterClassId": sessionStorage?.getItem('classIDForNotes') > 0 ? sessionStorage?.getItem('classIDForNotes') : rosterClassId,
            "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
        }
        this.props.getStudentClasses(data)
        this.getUserProfile(studentInfo)

    }
    // ---------UNSAFE_componentWillMount-------

    // ---------componentWillReceiveProps-------
    async UNSAFE_componentWillReceiveProps(nextProps) {
        let count = this.state.onCount;
        if (this.state.onSecondTime) {
            // ---------get Roster Class -------
            if (nextProps.notesData.action === 'GET_STUDENT_CLASSES_SUCCESS') {
                if (nextProps.notesData.Get_Student_Classes) {
                    if (nextProps.notesData.Get_Student_Classes.length > 0) {
                        let setId = ''
                        if (this.state.pageBYClass) {
                            setId = this.state.rosterClassId
                        } else {
                            // setId = nextProps.notesData.Get_Student_Classes[0].cc_class_id
                            setId = sessionStorage?.getItem('classIDForNotes') > 0 ? sessionStorage?.getItem('classIDForNotes') : nextProps.notesData.Get_Student_Classes[0].cc_class_id
                        }
                        this.setState({
                            EnrolledData: nextProps.notesData.Get_Student_Classes,
                            class_id: setId
                        })
                    }
                }
            }
            // ---------get Roster class-------

            if (nextProps.notesData.action === 'ADD_STUDENT_MISCELLANEOUS_FAILURE'
                || "EDIT_STUDENT_MISCELLANEOUS_FAILURE") {
                if (nextProps.notesData.error &&
                    (nextProps.notesData.error.message === 'Note limit has been exceeded. Please upgrade the plan'
                        || nextProps.notesData.error.message === "Media limit has been exceeded. Please upgrade the plan"
                        || nextProps.notesData.error.message === "Please update your subscription plan")) {
                    this.setState({
                        showPopUp: true
                    })
                }
            }

            // ---------get Roster Note -------
            if (nextProps.notesData.action === 'GET_STUDENT_NOTE_SUCCESS') {

                if (nextProps.notesData.Get_Student_Note) {
                    if (nextProps.notesData.Get_Student_Note.status == 200) {
                        let AttachemtData = []
                        if (this.state.page_no > 1) {
                            let AllNoteData = this.state.NotesData;
                            for (let item of nextProps?.notesData?.Get_Student_Note?.data?.response_data?.[0]) {
                                AllNoteData.push(item);
                            }
                            this.setState({
                                NotesData: AllNoteData,
                                totalNoteCount: nextProps?.notesData?.Get_Student_Note?.data.total_count
                            })
                            AttachemtData = AllNoteData;

                        } else {
                            this.setState({
                                StudentAllNoteDetails: nextProps.notesData.Get_Student_Note,
                                class_core_standard: nextProps.notesData.Get_Student_Note?.data?.class_core_standard,
                                NotesData: nextProps?.notesData?.Get_Student_Note?.data?.response_data,
                                student_details: nextProps.notesData.Get_Student_Note.student_details,
                                student_enrolled_classes: nextProps.notesData.Get_Student_Classes,
                                totalNoteCount: nextProps?.notesData?.Get_Student_Note?.data.total_count,
                                classPermission: nextProps.notesData.Get_Student_Classes?.[0]?.cc_class_share_permission
                            })
                            AttachemtData = nextProps?.notesData?.Get_Student_Note?.data?.response_data;

                        }

                        // ---Fatch Firebase Image---
                        let videoAttachment = [];
                        let audioAttachment = [];
                        let docAttachment = [];
                        this.setState({
                            fetchAttachmentLoader: true
                        })
                        for (let noteItem of AttachemtData) {
                            if (noteItem.cc_note_show_type === 'note') {
                                noteItem.groupArray = [];
                                if (noteItem.note_attachment) {
                                    noteItem.audioNotes = [];
                                    noteItem.videoNotes = [];
                                    for (let attachmnt of noteItem.note_attachment) {
                                        if (attachmnt.cc_attachment_url.includes("https") === true) {
                                            if (attachmnt.cc_attachment_type === 'audio') {
                                                audioAttachment.push(attachmnt)
                                                attachmnt.cc_attachment_fullurl = attachmnt.cc_attachment_url
                                            } else if (attachmnt.cc_attachment_type === 'other') {
                                                docAttachment.push(attachmnt);
                                                attachmnt.cc_attachment_fullurl = attachmnt.cc_attachment_url
                                            } else {
                                                videoAttachment.push(attachmnt);
                                                attachmnt.cc_attachment_fullurl = attachmnt.cc_attachment_url
                                            }
                                        }
                                        else {
                                            let fullUrl = await getNotesImage(attachmnt.cc_attachment_url, this.state.class_id);

                                            attachmnt.cc_attachment_fullurl = fullUrl;
                                            if (attachmnt.cc_attachment_type === 'audio') {
                                                audioAttachment.push(attachmnt)
                                            } else if (attachmnt.cc_attachment_type === 'other') {
                                                docAttachment.push(attachmnt);
                                            } else {
                                                videoAttachment.push(attachmnt)
                                            }
                                        }
                                        this.setState({
                                            NotesData: AttachemtData,
                                        }, () => {
                                        })
                                    }
                                }

                            }
                        }
                        // ---Fatch Firebase Image---
                        this.setState({
                            NotesData: AttachemtData,
                            audioAttachment: audioAttachment,
                            videoAttachment: videoAttachment,
                            docAttachment: docAttachment,
                            fetchAttachmentLoader: false
                        }, () => {
                        })
                    }
                }
            }
            // ---------get Roster Note-------
        } else {
            count++;
            this.setState({
                onCount: count
            })
            if (count > 1) {
                this.setState({
                    onSecondTime: true
                })
            }
        }


    }
    // ---------componentWillReceiveProps-------

    // ---------Get Image From Firebase-------
    async getUserProfile(value) {
        let data = ''
        if (value?.cc_student_pic_url?.includes("https") === true) {
            this.setState({
                Userprofile: value?.cc_student_pic_url,
                userProfileName: value?.cc_student_pic_url,
            })
        } else {
            if (value?.cc_student_pic_url === '') {
                this.setState({
                    Userprofile: '',
                    userProfileName: '',
                })
            }
            else {
                data = await getRosterImage(value?.cc_student_pic_url);
                this.setState({
                    Userprofile: data,
                    userProfileName: value?.cc_student_pic_url,

                })
            }
        }
    }
    // ---------Get Image From Firebase--------

    // ---------filterSet Get Note--------
    filterSet = (value) => {
        let setValue = ''
        if (value === this.state.filter_by) {
            setValue = "both";
        } else {
            setValue = value;
        }
        this.setState({
            filter_by: setValue,
            page_no: 1
        }, () => {
            this.getRosterNotes()
        })
    }
    // ---------filterSet Get Note--------

    // ---------Set Artifact Check/Uncheck --------
    setArtifact = (data) => {

        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;

        let cc_note_mark_artified = ''
        if (data?.student_Note_details?.cc_note_mark_artified === "no") {
            cc_note_mark_artified = 'yes';
        } else {
            cc_note_mark_artified = 'no'
        }
        const { class_id, filter_by } = this.state;

        const value = {
            "class_id": class_id,
            "student_details_array": [{
                "student_id": data?.student_Note_details?.cc_student_id,
                "student_note_id": data?.student_Note_details?.cc_note_id,
                "student_marked": cc_note_mark_artified
            }],
            "student_id": studentInfo?.cc_student_id,
            "filter_by": filter_by,
            "page_no": 1,
            "student_attachment_id": data?.student_Note_details?.note_attachment[0]?.cc_attachment_id,
            "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
        }
        this.setState({
            page_no: 1
        })

        this.props.setStudentArtifact(value);
    }
    // ---------Set Artifact Check/Uncheck --------

    // ---------Set Artifact Media Check/Uncheck --------
    setMediaArtifact = (item) => {
        console.log('item setting as artifact :>> ', item);
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;
        const { class_id, filter_by } = this.state;
        if (item?.cc_media_mark_artified === "no" || "") {
            let mediaValue = this.state.videoAttachment.map((val, i) => {
                if (val?.cc_attachment_id === item?.cc_attachment_id) {
                    return { ...val, cc_media_mark_artified: "yes" }
                } else {
                    return { ...val }
                }
            })
            this.setState({
                videoAttachment: mediaValue,
            })
            const apiData = {
                "cc_attachment_id": item?.cc_attachment_id,
                "cc_media_mark_artified": "yes",
                "class_id": class_id,
                "student_id": studentInfo?.cc_student_id,
                "filter_by": filter_by,
                "page_no": 1,
                "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
            }
            this.props.setMediaAttachmentArtifact(apiData)
        } else {
            let mediaValue = this.state.videoAttachment.map((val, i) => {
                if (val?.cc_attachment_id === item?.cc_attachment_id) {
                    return { ...val, cc_media_mark_artified: "no" }
                } else {
                    return { ...val }
                }
            })
            this.setState({
                videoAttachment: mediaValue,
            })
            const apiData = {
                "cc_attachment_id": item?.cc_attachment_id,
                "cc_media_mark_artified": "no",
                "class_id": class_id,
                "student_id": studentInfo?.cc_student_id,
                "filter_by": filter_by,
                "page_no": 1,
                "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
            }
            this.props.setMediaAttachmentArtifact(apiData)
        }
    }

    setDocArtifact = (item) => {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;
        const { class_id, filter_by } = this.state;
        if (item.cc_media_mark_artified === "no" || "") {
            let mediaValue = this.state.docAttachment.map((val, i) => {
                if (val.cc_attachment_id === item.cc_attachment_id) {
                    return { ...val, cc_media_mark_artified: "yes" }
                } else {
                    return { ...val }
                }
            })
            this.setState({
                docAttachment: mediaValue,
            })
            const apiData = {
                "cc_attachment_id": item?.cc_attachment_id,
                "cc_media_mark_artified": "yes",
                "class_id": class_id,
                "student_id": studentInfo?.cc_student_id,
                "filter_by": filter_by,
                "page_no": 1,
                "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
            }
            this.props.setMediaAttachmentArtifact(apiData)
        } else {
            let mediaValue = this.state.docAttachment.map((val, i) => {
                if (val?.cc_attachment_id === item?.cc_attachment_id) {
                    return { ...val, cc_media_mark_artified: "no" }
                } else {
                    return { ...val }
                }
            })
            this.setState({
                docAttachment: mediaValue,
            })
            const apiData = {
                "cc_attachment_id": item?.cc_attachment_id,
                "cc_media_mark_artified": "no",
                "class_id": class_id,
                "student_id": studentInfo?.cc_student_id,
                "filter_by": filter_by,
                "page_no": 1,
                "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
            }
            this.props.setMediaAttachmentArtifact(apiData)
        }
    }

    onSlide = (item) => {
        if (item === "prev") {
            let idVal = this.state.selectedMediaIndex - 1
            if (idVal >= 0) {
                this.setState({
                    selectedMediaIndex: idVal,
                })
            } else {
                this.setState({
                    selectedMediaIndex: this.state.videoAttachment.length - 1
                })
            }
        }
        if (item === "next") {
            let idVal = this.state.selectedMediaIndex
            if (idVal >= this.state.videoAttachment.length - 1) {
                this.setState({
                    selectedMediaIndex: 0
                })
            } else {
                this.setState({
                    selectedMediaIndex: idVal + 1
                })
            }
        }
    }
    // ---------Set Artifact Media Check/Uncheck --------


    // ---------Add Misellane --------
    addMisellane = () => {
        this.setState({
            isSubmit: true,
            page_no: 1
        })
        const { class_id, misc_note, filter_by, page_no, misc_Type } = this.state
        if (!misc_note) {
            return;
        }
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;

        let value = {
            "class_id": class_id,
            "misc_note": misc_note,
            "student_id": studentInfo?.cc_student_id,
            "filter_by": filter_by,
            "page_no": page_no,
            "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
        }
        if (misc_Type === 'add') {
            this.props.addStudentMiscellaneous(value)
        } else {
            const { misc_Selected_Id } = this.state
            value.misc_note_id = misc_Selected_Id
            this.props.editStudentMiscellaneous(value)
        }
        this.handleClose();

    }
    // ---------Add Misellane --------


    // ---------Close Miscellaneous Note Modal--------
    handleClose = () => {
        this.setState({
            show: false,
            misc_note: '',
            isSubmit: false
        })
    }
    // ---------Close Miscellaneous Note Modal--------

    // ---------Edit Miscellaneous Note Modal--------
    editMiscellaneous = (value) => {
        this.setState({
            misc_note: value.data.cc_misc_note_text,
            show: true,
            misc_Type: "edit",
            misc_Selected_Id: value.data.cc_misc_note_id,
        })
    }
    // ---------Edit Miscellaneous Note Modal--------


    // ---------Delete Miscellaneous Note Modal--------
    deleteMiscellaneous = (value) => {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;
        const { class_id, filter_by } = this.state
        const data = {
            "class_id": class_id,
            "student_id": studentInfo?.cc_student_id,
            "filter_by": filter_by,
            "page_no": 1,
            "misc_note_id": value.data.cc_misc_note_id,
            "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote
        }
        this.setState({
            ConfirmationShow: true,
            ConfirmationType: "Miscellaneous",
            whatToDelete: data,
            page_no: 1

        })
    }
    // ---------Delete Miscellaneous Note Modal--------


    // ---------Add Miscellaneous Note Modal--------
    handleShow = () => {
        this.setState({
            show: true,
            misc_note: '',
            misc_Type: "add",
            misc_Selected_Id: ''
        })
    }
    // ---------Add Miscellaneous Note Modal--------

    handleClosePopUp = (e) => {
        this.setState({
            showPopUp: false,
        })
    }

    // ---------confirmation Delete--------
    confirmationDelete = () => {
        if (this.state.ConfirmationType === 'Miscellaneous') {
            this.props.deleteStudentMiscellaneous(this.state.whatToDelete);
        }
        if (this.state.ConfirmationType === 'Note') {
            this.props.deleteStudentNote(this.state.whatToDelete);
        }
        this.setState({
            ConfirmationShow: false,
            noteContainMedia: false,
            ConfirmationType: "",
            whatToDelete: []

        })
    }
    // ---------confirmation Delete--------

    // ---------confirm group note delte-----
    confirmationGroupNoteDelete = () => {
       const { location = {} } = this.props
        const { state = {} } = location;
        const { studentInfo = '' } = state;
        const { class_id, filter_by } = this.state
        this.setState({
            showDeleteGroupNoteModal: false
        })
        const data = {
            "class_id": class_id,
            "student_id": studentInfo?.cc_student_id,
            "filter_by": filter_by,
            "page_no": 1,
            "academic_id" : this.props?.history?.location?.state?.academicYearIDFromClassNote,
            "note_id" : this.state.groupNoteDeleteId,
            "showDeleteGroupNoteModal": false
        }
       this.props.deleteGroupNoteFromSingleStudent(data)
    }
    // ---------confirm group note delete----


    // ---------Load More--------
    loadMore = () => {
        let page_no = this.state.page_no;
        page_no++
        this.setState({
            page_no: page_no,
            isMoreLoad: true
        }, () => {
            this.getRosterNotes()
        })
    }
    // ---------Load More--------


    // ---------Select Class On Change--------
    selectOnChange(value, ...args) {
        sessionStorage.setItem('classIDForNotes', args[0].value)
        sessionStorage.setItem('classNameForNotes', args[0].name)
        let classArr = []
        if (this.state.classListData && this.state.classListData.length > 0) {
            classArr = this.state.classListData.filter((item) => {
                if (item?.details?.cc_class_share_class_id && (item?.details?.cc_class_share_class_id > 0 || item?.details?.cc_class_share_class_id.lengh > 0)) {
                    return item?.details?.cc_class_share_class_id == value
                } else {
                    return item?.details?.cc_class_id == value
                }
            })
        } else {
            classArr = this.state.student_enrolled_classes.filter((item) => {
                if (item?.details?.cc_class_share_class_id && (item?.details?.cc_class_share_class_id > 0 || item?.details?.cc_class_share_class_id.lengh > 0)) {
                    return item?.details?.cc_class_share_class_id == value
                } else {
                    return item?.details?.cc_class_id == value
                }
            })
        }
        
        this.setState({
            isSubmit: false,
            page_no: 1,
            class_id: value,
            selectedClassName: args[0].name,
            classPermission: classArr?.[0]?.details?.cc_class_share_permission
        }, () => {
            this.getRosterNotes()
        })

    }
    // ---------Select Class On Change--------

    // ---------Input Handle change-------
    OnChangeTextArea = event => {
        this.setState(
            {
                isSubmit: false,
                [event.target.name]: event.target.value,
            }
        );
    };
    // ---------Input Handle change-------


    // ---------OpenAttachementModal-------
    OpenAttachementModal = (value) => {
        let attachmentIndex = this.state.videoAttachment.indexOf(value)

        this.setState({
            showAttachement: true,
            showAttachementURL: value.cc_attachment_fullurl,
            showAttachementName: value.cc_attachment_name,
            showAttachementType: value.cc_attachment_type,
            showAttachementId: value.cc_attachment_id,
            isMediaArtified: value.cc_media_mark_artified,
            selectedMediaIndex: attachmentIndex
        })
    }
    // ---------OpenAttachementModal-------

    // ---------OpenAttachementModal-------
    OpenDocAttachementModal = (value) => {
        let attachmentIndex = this.state.docAttachment.indexOf(value)
        let encodeUrlStr = encodeURIComponent(value.cc_attachment_fullurl)
        this.setState({
            showDocAttachement: true,
            showDocAttachementURL: encodeUrlStr,
            showDocAttachementName: value.cc_attachment_name,
            showDocAttachementType: value.cc_attachment_type,
            showDocAttachementId: value.cc_attachment_id,
            isDocMediaArtified: value.cc_media_mark_artified,
            selectedDocIndex: attachmentIndex
        })
    }
    // ---------OpenAttachementModal-------

    // --------------------Go Back ------------------
    goBack = () => {
        const { location = {} } = this.props
        const { state = {} } = location;
        const { directAdd, deletedMultimedia, pageType
        } = state;
        const { pictureVideoList, audioList, docList } = this.state
        if (pageType !== 'Edit') {
            let attachment_array = [];
            for (let item of docList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": ""
                }
                attachment_array.push(attachment)
            }
            for (let item of pictureVideoList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": ""
                }
                attachment_array.push(attachment)
            }
            for (let item of audioList) {
                let attachment = {
                    "cc_attachment_url": item.cc_attachment_url,
                    "cc_attachment_size": item.cc_attachment_size,
                    "cc_attachment_type": item.cc_attachment_type,
                    "cc_attachment_operation": item.cc_attachment_operation,
                    "cc_attachment_name": item.cc_attachment_name
                }
                attachment_array.push(attachment)
            }
            attachment_array && attachment_array.length > 0 && attachment_array.map(async (val) => {
                if (val.cc_attachment_operation === "remove") {
                    const data = JSON.parse(sessionStorage.getItem('UserData'))
                    const FinfFolderNameArray = data.cc_user_email_id.split("@")
                    const FolderName = FinfFolderNameArray[0]
                    const fileName = val.cc_attachment_url;
                    await storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${fileName}`).delete()
                }
            })
        } else {
            deletedMultimedia && deletedMultimedia.length > 0 && deletedMultimedia.map(async (val) => {
                if (val.cc_attachment_operation !== "Unchanged") {
                    const data = JSON.parse(sessionStorage.getItem('UserData'))
                    const FinfFolderNameArray = data.cc_user_email_id.split("@")
                    const FolderName = FinfFolderNameArray[0]
                    const fileName = val.cc_attachment_url;
                    await storage.ref(`${FirebseStroageFolder}/${FolderName}/notes/notes-${this.state.class_id}/${fileName}`).delete()
                }
            })
        }
        if (directAdd === "directAdd") {
            if (this.state.isSaved) {
                this.props.history.push(routes.STUDENTNOTE, {
                    studentInfo: JSON.parse(sessionStorage.getItem('selectedprofile')),
                    academicYear: sessionStorage.getItem('selectedYear'),
                    From: "Academic",
                    BackPage: 'Academic Year',
                    directAdd: 'directAddNo'
                })
            } else {
                this.setState({
                    confirmationForBack: true,
                    confirmationType: 'back'
                })
            }
        } else {
            if (this.state.isSaved) {
                this.props.history.goBack()
            } else {
                this.setState({
                    confirmationForBack: true,
                    confirmationType: 'back'
                })
            }
        }
    }
    // --------------------Go Back ------------------
    // ---------BackTO-------
    BackToDash = () => {
        this.props.history.replace("/home/academic")

    }
    // ---------BackTO-------
    GropuStudentNotes = (data) => {
    }

    RedirectAddNoteHandler = (value) => {
        this.props.history.push(routes.STUDENTADDNOTE,
            {
                pageType: 'New',
                data: '',
                studentInfo: this.state.studentInfo,
                academicYear: this.state.academicYear,
                student_enrolled_classes: this.state.student_enrolled_classes,
                NotesData: this.state.NotesData,
                StudentAllNoteDetails: this.state.StudentAllNoteDetails,
                class_id: this.state.class_id,
                directAdd: "directAdd",
                classList: this.state.newClassListData,
                BackPage: this.props.history.location.state.BackPage == "Notecls" ? "FrmCls" : "AcademicYear"
            })
    }

    render() {

        let {
            StudentAllNoteDetails,
            student_enrolled_classes,
            NotesData, audioAttachment,
            videoAttachment,
            docAttachment,
            fetchAttachmentLoader,
            studentInfo,
            academicYear,
            class_id,
            classPermission,
        } = this.state;
        const { Notesloader, loader, classId } = this.props
        const { location = {} } = this.props
        const { state = {} } = location;

        const _ = require('lodash');
        const newClassDataList = student_enrolled_classes?.map((obj, i) => (
            {
                ...obj,
                name: student_enrolled_classes[i].cc_class_name,
                value: student_enrolled_classes[i].cc_class_id
            }

        ));

        let optionData = newClassDataList?.map((item) => ({
            ...item,
            name: item.cc_class_name,
            value: item.cc_class_id,
        }))

        const options = [
            {
                type: "group",
                name: "",
                items: newClassDataList
            }
        ];


        return (
            <div className='noteMainContainer container'>
                <div
                    style={{
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        background: "#f0f0f0",
                        height: 176,
                    }}
                    className="sticky-header"
                >
                    {/* ---------------noteMainContainer------------ */}
                    <Row className='px-3'>
                        {/* ---------------Header Bar------------ */}
                        <Col xs="12" sm="12" md="12" className='headerBar'>
                            <Col xs="5" className=" note-header-title ">
                                <div className='stdNoteBackDiv'>
                                    <img className='calIcon' src={back} alt='' width='60' height='40' onClick={() => { this.BackToDash() }} />
                                </div>
                                <div className='stdNoteDetailTextDiv'>
                                    <div className='stdNoteDisplayFlex'>

                                        {
                                            this.props.history?.location?.state?.BackPage == "Notecls" ?
                                                <>
                                                    <p className='currentYear' onClick={() => { 
                                                        // this.BackToDash()
                                                        window.history.go(-2)
                                                         }}><b>Class</b></p>
                                                    <i className="classForIcon material-icons">chevron_right</i>
                                                    <p className='currentYear' onClick={() => {
                                                        //  this.BackToDash()
                                                        this.props.history.goBack()
                                                          }}><b>Notes</b></p>

                                                </>

                                                : ""
                                        }
                                        {
                                            this.props.history?.location?.state?.BackPage == "Roster" ?
                                                <>
                                                    <p className='currentYear' onClick={() => { this.props.history.goBack() }}><b>Roster</b></p>
                                                    {/* <i className="classForIcon material-icons">chevron_right</i> */}
                                                    {/* <p className='currentYear' onClick={() => { this.BackToDash() }}><b>Notes</b></p> */}
                                                </>
                                                : ""
                                        }
                                        {
                                            this.props.history?.location?.state?.BackPage != "Class Roster" && this.props.history?.location?.state?.BackPage != "Notecls" && this.props.history?.location?.state?.BackPage != "Roster" ?
                                                <p className='currentYear' onClick={() => { this.BackToDash() }}><b>{this.state.academicYear}</b></p>
                                                : ""
                                        }

                                        {
                                            this.props.history?.location?.state?.BackPage != "Class Roster" && this.props.history?.location?.state?.BackPage != "Notecls" ?
                                                (this.props.history?.location?.state?.BackPage != "Academic Year") ?
                                                    <i className="classForIcon material-icons">chevron_right</i> : ""
                                                : ""
                                        }
                                        <p className='stdNoteGrade1' onClick={() => window.history.go(-2)}>{this.props.history?.location?.state?.BackPage == "Class Roster" ? "Class" : (this.props.history?.location?.state?.BackPage == "Roster Enrolled" || this.props.history?.location?.state?.BackPage == 'Student Gradebook') ? "Roster" : ""}</p>

                                        <i style={this.props.history?.location?.state?.BackPage == "Class Roster" ? { display: "block" } : { display: "none" }} className="classForIcon material-icons">chevron_right</i>
                                        {
                                            this.props.history?.location?.state?.BackPage == "Roster" ?
                                                <p className='currentYear' onClick={() => { this.props.history.goBack() }}><b>Notes</b></p>
                                                : ""
                                        }
                                        {
                                            <p className='stdNoteGrade1' onClick={() => { this.props.history.goBack() }}>{this.props.history?.location?.state?.BackPage == "Class Roster" ? "Roster" : ""}</p>
                                        }
                                        <p className='stdNoteGrade1' onClick={() => { this.props.history.goBack() }}>{this.props.history?.location?.state?.BackPage == "Class Notes" ? "Notes" : ""}</p>
                                        <i className="classForIcon material-icons">chevron_right</i>

                                        <p className='stdNoteGrade2'>Student Notes </p>
                                    </div>
                                </div>
                            </Col>
                            {/* ---------------Header Bar------------ */}
                            <Col className='powerCenter' xs="2">

                            </Col>
                            <Col xs="5" className="text-right addNoteBtn">
                                {!Notesloader && classPermission !== null && classPermission === 'read' ? <Button
                                
                                    variant="primary" className="disabledAdd-note"  data-testid="noteButton">
                                    <img src={addNoteIcon} alt='' className='add-note-img' />
                                    Note
                                </Button>
                                    : <Button disabled={Notesloader} onClick={() => {


                                        if (student_enrolled_classes.length === 0) {
                                            createNotification('error', ' No students enrolled. Please enrol students to confer Notes.');
                                            return;
                                        }
                                        sessionStorage.setItem("selectedprofile", JSON.stringify(studentInfo));
                                        sessionStorage.setItem("selectedYear", (academicYear));
                                        sessionStorage.removeItem("audiosummarizedAItext");
                                        this.props.history.push(routes.STUDENTADDNOTE,
                                            {
                                                pageType: 'New',
                                                data: '',
                                                studentInfo: studentInfo,
                                                academicYear: academicYear,
                                                student_enrolled_classes: student_enrolled_classes,
                                                NotesData: NotesData,
                                                StudentAllNoteDetails: StudentAllNoteDetails,
                                                class_id: class_id,
                                                directAdd: "directAdd",
                                                classList: this.state.newClassListData,
                                                BackPage: this.props.history.location.state.BackPage == "Notecls" ? "FrmCls" : "AcademicYear",
                                                class_core_standard: this.state.class_core_standard
                                            })
                                            {
                                               console.log('coreStandardNotes454545 :>> ', this.state.coreStandardNotes)
                                            }
                                    }
                                    }
                                        variant="primary" className="add-note"
                                        data-testid="noteButton"
                                        >
                                        <img src={addNoteIcon} alt='' className='add-note-img' />
                                        Note
                                    </Button>
                                }
                            </Col>
                        </Col>
                    </Row>
                    {/* ------ Filters and Academic Year----- */}
                    <Card className="filter-section br-8">
                        <Card.Body>
                            <Row>
                                <Col xs="6" md="4" lg="5" className="note-header-total-std">
                                    <span className="note-header-total-std-title">
                                        Total Notes: {this.state.totalNoteCount < 10 ? "0" + this.state.totalNoteCount : this.state.totalNoteCount}
                                    </span>
                                    <span className='seperator btn-group'></span>
                                    <span className="btn-group ViewBtn btn-group-sm" role="group" aria-label="Basic example">
                                        <div defaultActiveKey="tab_sort_timeline" id="" className="section-tabs">
                                            <div eventKey="tab_sort_timeline" title="Timeline" className={this.state.tabView === 'timeLine' ? 'blueTimeLineTxt d-flex flex-wrap align-items-center' : ' whiteTimeLineTxt d-flex flex-wrap align-items-center'} onClick={() => { this.setState({ tabView: 'timeLine' }) }}>
                                                Timeline
                                            </div>
                                            <div eventKey="tab_expanded_timeline" title="Expanded View" className={this.state.tabView === 'expanded' ? 'blueExpandedTxt d-flex flex-wrap align-items-center' : ' whiteExpandedTxt d-flex flex-wrap align-items-center'} onClick={() => { NotesData && NotesData.length > 0 && this.setState({ tabView: 'expanded' }) }}>
                                                Expanded View
                                            </div>
                                        </div>
                                    </span>

                                </Col>
                                <Col xs="6" md="4" lg="3" className="st-note-header-select text-center">
                                    <Form.Group controlId="exampleForm.ControlSelect1">
                                        <div className="dropdownMainContainer">
                                            {/* <div className="iconDiv"><i className="fa fa-search searchFiltersIcon"></i></div> */}
                                            <img src={search} alt='' className='header-dropdown-main-container-CND dropdown-search-icon-CND' />
                                            <SelectSearch
                                                // options={options}
                                                options={optionData}
                                                // value={class_id}
                                                value={(this?.state?.class_id.length > 0 || this?.state?.class_id > 0) ? { name: this?.state?.selectedClassName?.length > 0 ? this?.state?.selectedClassName : (sessionStorage?.getItem('classNameForNotes')?.length > 0 ? sessionStorage?.getItem('classNameForNotes') : this?.state?.student_enrolled_classes?.[0]?.cc_class_name), value: this.state.class_id } : { class_id }}
                                                name="Workshop"
                                                placeholder="Search class*"
                                                search
                                                onChange={this.selectOnChange.bind(this)}
                                                filterOptions={fuzzySearch}
                                                disabled={(this.props.history?.location?.state?.BackPage == "Notecls" || this?.props?.history?.location?.state?.pageBYClassRoster) ? true : false}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                                <Col xs="12" md="4" lg="4" className="pl-2 UpperFilterDiv">
                                    {/* ---------------Filters------------ */}
                                    <div className='filterDiv'>

                                        {this.state.filter_by === 'both' ?

                                            <div className="showAllBtn filterSubDiv blueActiveButton" >
                                                <p className='filterText'>Show All</p>
                                            </div>
                                            :
                                            <div className="showAllDisBtn filterSubDiv" onClick={() => { this.filterSet("both") }}>
                                                <p className='filterText'>Show All</p>
                                            </div>
                                        }
                                        {this.state.filter_by === 'marked_yes' ?
                                            <div className='artifactsNote filterSubDiv blueActiveButton' >
                                                <p className='filterText'>Artifacts</p>
                                            </div> :
                                            <div className='artifactsNote filterSubDiv ' onClick={() => { this.filterSet("marked_yes") }}>
                                                <p className='filterText'>Artifacts</p>
                                            </div>
                                        }
                                        {this.state.filter_by === 'misc' ?
                                            <div className='miscellaneousNote filterSubDiv blueActiveButton filterMiscel' >
                                                <p className='filterText'>Miscellaneous</p>
                                            </div> :
                                            <div className='miscellaneousNote filterSubDiv filterMiscel' onClick={() => { this.filterSet("misc") }}>
                                                <p className='filterText'>Miscellaneous</p>
                                            </div>
                                        }
                                    </div>
                                    {/* ---------------Filters------------ */}
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>

                {/* ------ Filters and Academic Year----- */}

                <div ref={this.DataRef}
                    // // style={{height : "calc(100vh - 110px)"}}
                    style={{
                        // height : StudentList.length > 0 ? `70vh` : "82vh", 
                        height: NotesData?.length > 0 ? '82vh' : "85vh",
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}
                    onScroll={this.handleScroll}>
                    <Row>
                        {/* ---------------left panel------------ */}
                        <Col md="4" lg="3" className="left-section">
                            <Card className="br-8">
                                <Card.Body>

                                    {/* ---------------Profile Section------------ */}

                                    {/* ---------------Profile Details------------ */}
                                    <Card.Text as="div">
                                        <Row className="profile-card">
                                            <Col xs="12" className="profile-col">
                                                <div className="profile-card-img">
                                                    {this.state.Userprofile === '' ?
                                                        <h5 className='noteImageDummyText'>{studentInfo?.cc_student_first_name?.charAt(0)}{studentInfo?.cc_student_last_name?.charAt(0)}</h5>

                                                        :
                                                        <Card.Img className="profile-img" variant="top" alt='' src={this.state.Userprofile} />}
                                                </div>
                                                <div className={`profile-details-col ${studentInfo?.cc_student_email_id?.length > 0 ? 'd-flex flex-wrap flex-column pr b-6' : ''}`}
                                                // style={studentInfo?.cc_student_email_id.length > 0 && {position : 'relative', bottom : 6}}
                                                >
                                                    <span className={`profile-name ${studentInfo?.cc_student_email_id?.length > 0 ? 'mb-13' : ''}`}
                                                    // style={studentInfo?.cc_student_email_id.length > 0 && {marginBottom : 13}}
                                                >{studentInfo?.cc_student_last_name?.replace(/(.{11})..+/, "$1…")} {studentInfo?.cc_student_first_name?.replace(/(.{11})..+/, "$1…")}</span>

                                                    <span className="profile-mail">
                                                        {_.truncate(studentInfo?.cc_student_email_id, {
                                                            'length': 30,
                                                            'omission': '...'
                                                        })}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                            {/* ---------------Classroom Section------------ */}
                            <Card className="br-8 mt-15 mb-15">
                                <Card.Body>
                                    <Card.Title className="classRoom-card-title">Classroom Attachments  </Card.Title>
                                    <Card.Text as="div">
                                        <Row className="profile-card-attachments">
                                            <Col xs="12">
                                                {/* ---------------Tab Layout------------ */}
                                                <Tabs defaultActiveKey="tab1" id="" className="section-tabs">

                                                    {/* ---------------Tab Layout (Videos/Images)------------ */}
                                                    <Tab eventKey="tab1" title="Videos/Images">
                                                        {videoAttachment && videoAttachment.length > 0 || docAttachment.length > 0 ? <div className="classroom-attchements">
                                                            <div> {Notesloader ? <img src={loaderImag} alt='' className='loaderIconCss' /> : ""}</div>
                                                            {videoAttachment.map((item, i) => {

                                                                return (
                                                                    item.cc_attachment_type === "image" ? <div key={i} className="note" onClick={() => { this.OpenAttachementModal(item) }} >
                                                                        {item.cc_attachment_type === "image" && (<div className="imageArti">
                                                                            <img src={item.cc_attachment_fullurl} alt="" width="60" height="60" />
                                                                            {!Notesloader && item.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                                                        </div>)}
                                                                    </div> :
                                                                        <div key={i} className="note" onClick={() => { this.OpenAttachementModal(item) }} style={{ backgroundImage: `url(${item.cc_attachment_fullurl})` }}>
                                                                            {item.cc_attachment_type !== "image" && (
                                                                                <div className="img-div">
                                                                                    <img src={playIcon} alt='' width="18" height="18" />
                                                                                    {item.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                                                                </div>
                                                                            )}

                                                                        </div>)
                                                            })}
                                                            {docAttachment.map((item, i) => {
                                                                return (
                                                                    <div key={i} className="noteDoc" onClick={() => { this.OpenDocAttachementModal(item) }} >
                                                                        {item.cc_attachment_url.split(".").pop() === "pdf" && <div className="imageArti">
                                                                            <img src={note_pdf} alt="" width="60" height="60" />
                                                                            {!Notesloader && item.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                                                        </div>}
                                                                        {item.cc_attachment_url.split(".").pop() === "doc" || item.cc_attachment_url.split(".").pop() === "docx" ? <div className="imageArti">
                                                                            <img src={note_doc} alt="" width="60" height="60" />
                                                                            {!Notesloader && item.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                                                        </div> : ""}
                                                                        {item.cc_attachment_url.split(".").pop() === "ppt" || item.cc_attachment_url.split(".").pop() === "pptx" ? <div className="imageArti">
                                                                            <img src={note_ppt} alt="" width="60" height="60" />
                                                                            {!Notesloader && item.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                                                        </div> : ""}
                                                                        {item.cc_attachment_url.split(".").pop() === "xls" || item.cc_attachment_url.split(".").pop() === "xlsx" ? <div className="imageArti">
                                                                            <img src={note_xls} alt="" width="60" height="60" />
                                                                            {!Notesloader && item.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                                                        </div> : ""}
                                                                        {item.cc_attachment_url.split(".").pop() === "csv" && <div className="imageArti">
                                                                            <img src={note_csv} alt="" width="60" height="60" />
                                                                            {!Notesloader && item.cc_media_mark_artified === "yes" && <span className="badgeDot"></span>}
                                                                        </div>}
                                                                    </div>)
                                                            })}
                                                        </div> :
                                                            <div className="classroom-attchements">
                                                                {/* ------ Loader Div----- */}

                                                                {fetchAttachmentLoader ? <div>
                                                                    <img src={loaderImag} alt='' className='loaderIconCss' />
                                                                </div> :
                                                                    <div>
                                                                        {loader ? "" :
                                                                            <div data-toggle="modal" data-target="#myModal2">
                                                                                {Notesloader ? "" : <p className='noAttachementText'> No Videos/Images</p>}
                                                                            </div>}
                                                                    </div>}
                                                                {/* ------ Loader Div----- */}
                                                            </div>}
                                                    </Tab>
                                                    {/* ---------------Tab Layout (Videos/Images)------------ */}

                                                    {/* ---------------Tab Layout (Audio)------------ */}
                                                    <Tab eventKey="tab2" title="Audio">
                                                        {audioAttachment && audioAttachment.length > 0 ? <div className="classroom-attchements">
                                                            {audioAttachment.map((item, index) => {
                                                                return (
                                                                    <div key={index} className="note" style={{ backgroundImage: `url(${item.cc_attachment_fullurl})` }} >

                                                                        <div className="img-div" onClick={() => { this.OpenAttachementModal(item) }}>
                                                                            <img src={micIcon} alt='' width="18" height="18" />
                                                                        </div>

                                                                    </div>
                                                                )
                                                            })}
                                                        </div> :
                                                            <div className="classroom-attchements">
                                                                {/* ------ Loader Div----- */}

                                                                {fetchAttachmentLoader ? <div>
                                                                    <img src={loaderImag} alt='' className='loaderIconCss' />
                                                                </div> :
                                                                    <div>
                                                                        {loader ? "" :
                                                                            <div data-toggle="modal" data-target="#myModal2">
                                                                                {Notesloader ? "" : <p className='noAttachementText'> No Audio</p>}
                                                                            </div>}
                                                                    </div>}
                                                                {/* ------ Loader Div----- */}
                                                            </div>}

                                                    </Tab>
                                                    {/* ---------------Tab Layout (Audio)------------ */}
                                                </Tabs>
                                                {/* ---------------Tab Layout------------ */}
                                            </Col>
                                        </Row>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            {/* ---------------Classroom Section------------ */}

                        </Col>
                        {/* ---------------left panel------------ */}


                        {/* ---------------Right panel------------ */}
                        <Col md="8" lg="9" className="right-section">

                            {/* ------ Loader Div----- */}
                            {Notesloader && <div>
                                <img src={loaderImag} alt='' className='loaderIconCss' />
                            </div>}
                            {/* ------ Loader Div----- */}

                            {/* ------ TimeLine and ExpandedTimeline----- */}

                            <Card className="timeline-section mt-15 br-8">
                                <Card.Body>
                                    <div>
                                        {this.state.tabView === "timeLine" && <>

                                            {NotesData && NotesData.length > 0 ?
                                                <div

                                                // id="scrollableDiv" style={{ height: 300, overflow: "auto" }}
                                                >
                                                    <div id="studentHeight">
                                                        <StudentNoteTimelineComponent
                                                            type={'show'}
                                                            StudentAllNoteDetails={StudentAllNoteDetails}
                                                            NoteData={NotesData}
                                                            TotalCount={this.state.totalNoteCount}
                                                            setArtifact={this.setArtifact.bind(this)}
                                                            editMiscellaneous={this.editMiscellaneous.bind(this)}
                                                            deleteMiscellaneous={this.deleteMiscellaneous.bind(this)}
                                                            deleteStudentNote={this.deleteStudentNote.bind(this)}
                                                            GropuStudentNotes={this.GropuStudentNotes.bind(this)}
                                                            loadMore={this.loadMore.bind(this)}
                                                            handleClose={this.handleClose}
                                                            handleShow={this.handleShow}
                                                            props={this.props}
                                                            studentInfo={studentInfo}
                                                            academicYear={academicYear}
                                                            class_id={class_id}
                                                            student_enrolled_classes={student_enrolled_classes}
                                                            classPermission={classPermission}
                                                            academicYearIDFromStudentNoteDashboard={this.props?.history?.location?.state?.academicYearIDFromClassNote}
                                                            RedirectAddNoteHandler={this.RedirectAddNoteHandler.bind(this)}
                                                            classcorestandards = {this.state.class_core_standard}
                                                        />

                                                    </div>

                                                    {Notesloader ?
                                                        <div>
                                                            {Notesloader && <div>
                                                                <img src={loaderImag} alt='' className='loaderIconCss' />
                                                            </div>}
                                                        </div> : ""}
                                                </div>
                                                :
                                                /* ------ No Notes ----- */
                                                <div className='noRoster'>
                                                    {loader ? "" :
                                                        <div data-toggle="modal" data-target="#myModal2">
                                                            {Notesloader ? "" :
                                                                <div>
                                                                    {NotesData?.length < 1 && <div><button className="selfAddMisc"
                                                                        style={{
                                                                            opacity: classPermission !== null && classPermission === 'read' && "0.65"
                                                                        }}
                                                                        onClick={() => {
                                                                            if (classPermission !== null && classPermission === 'read') {
                                                                                return;
                                                                            } else {
                                                                                this.handleShow()
                                                                            }
                                                                        }} >Add Miscellaneous Note</button>
                                                                        <p className='tapToAddText'> No Notes</p></div>}
                                                                </div>
                                                            }
                                                        </div>}

                                                </div>
                                            }
                                        </>
                                        }
                                        {this.state.tabView === "expanded" &&
                                            <>
                                                {NotesData && NotesData.length > 0 ?
                                                    <StudentNoteExpandedTimelineComponent
                                                        type={'show'}
                                                        NoteData={NotesData}
                                                        StudentAllNoteDetails={StudentAllNoteDetails}
                                                        TotalCount={this.state.totalNoteCount}
                                                        setArtifact={this.setArtifact.bind(this)}
                                                        handleClose={this.handleClose}
                                                        editMiscellaneous={this.editMiscellaneous.bind(this)}
                                                        deleteMiscellaneous={this.deleteMiscellaneous.bind(this)}
                                                        deleteStudentNote={this.deleteStudentNote.bind(this)}
                                                        loadMore={this.loadMore.bind(this)}
                                                        OpenAttachementModal={this.OpenAttachementModal.bind(this)}
                                                        OpenDocAttachementModal={this.OpenDocAttachementModal.bind(this)}
                                                        GropuStudentNotes={this.GropuStudentNotes.bind(this)}
                                                        handleShow={this.handleShow}
                                                        props={this.props}
                                                        studentInfo={studentInfo}
                                                        academicYear={academicYear}
                                                        class_id={class_id}
                                                        student_enrolled_classes={student_enrolled_classes}
                                                        classPermission={classPermission}
                                                        academicYearIDFromStudentNoteDashboard={this.props?.history?.location?.state?.academicYearIDFromClassNote}
                                                        RedirectAddNoteHandler={this.RedirectAddNoteHandler.bind(this)}
                                                    />
                                                    :
                                                    /* ------ No Notes ----- */
                                                    <div className='noRoster'>
                                                        {loader ? "" :
                                                            <div style={{ display: "inline-block" }} data-toggle="modal" data-target="#myModal2">
                                                                {Notesloader ? "" :
                                                                    <div style={{ display: "inline-block", opacity: classPermission !== null && classPermission === 'read' && "0.65" }} >
                                                                        {NotesData.length < 1 && <div><button className="selfAddMisc" onClick={() => {
                                                                            if (classPermission !== null && classPermission === 'read') {
                                                                                return;
                                                                            } else {
                                                                                this.handleShow()
                                                                            }

                                                                        }} >Add Miscellaneous Note</button>
                                                                            <p className='tapToAddText'> No Notes</p></div>}
                                                                    </div>
                                                                }
                                                            </div>}
                                                    </div>}
                                            </>
                                        }
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* ------ No Notes----- */}

                        </Col>
                        {/* ---------------Right panel------------ */}

                    </Row>
                </div>
                {/* ---------------noteMainContainer------------ */}



                {/* ---------------Add Miscellaneous Modal------------ */}
                <Modal centered className="add-miscellaneous-modal br-8" show={this.state.show} >
                    <Modal.Header>
                        <Modal.Title>Add Miscellaneous Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <Form.Control name='misc_note' as="textarea" onChange={this.OnChangeTextArea} value={this.state.misc_note} rows="6" placeholder="Here goes some miscellaneous notes" />
                        {(!this.state.misc_note && this.state.isSubmit) && <p className="errormessageRegister">Please enter Miscellaneous Note*</p>}
                    </Modal.Body>


                    <Modal.Footer>
                        <Button variant="danger add-misc-close-btn" onClick={this.handleClose}>
                            <img src={closeIcon} alt='' />
                        </Button>
                        <Button variant="success add-misc-close-btn" onClick={this.addMisellane}>
                            <img src={tickIcon} alt='' />
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* ---------------Add Miscellaneous Modal------------ */}

                {/* --------------Confirmation Modal------------ */}
                <Modal centered className="delete-modal-warning br-8" show={this.state.ConfirmationShow}>
                    <Modal.Header>
                        <Modal.Title>Delete {this.state.ConfirmationType}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.ConfirmationType === 'Note' && <div>
                            {this.state.noteContainMedia ? <p>*Note contain Attachments and Audio Notes.</p> : ""}
                        </div>}
                        {this.state.ConfirmationType === 'Note' ? <p className="confirm-text">Are you sure you want to delete <br></br> this note?</p> :
                            <p className="confirm-text">Are you sure you want to delete <br></br> {this.state.ConfirmationType}?</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn" onClick={() => {
                            this.setState({
                                ConfirmationType: '',
                                ConfirmationShow: false,
                                noteContainMedia: false
                            })
                        }}>
                            Cancel
                        </Button>
                        <Button variant="default" className="delete-btn BR-8" onClick={() => { this.confirmationDelete() }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* --------------Confirmation Modal------------ */}

                {/* -----------------Delete Group Note Modal -----------------*/}
                <Modal centered className="delete-modal-warning br-8" show={this.state.showDeleteGroupNoteModal}>
                    <Modal.Header>
                        <Modal.Title>
                        <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>
                <img
                  src={warningIcon}
                  alt=""
                  style={{ width: "60px", height: "60px" }}
                />
                <span
                  style={{
                    fontSize: "24px",
                    color: "#ff1f1f",
                    marginTop: "10px",
                  }}
                >
                  DELETE WAIT!
                </span>
              </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {<p className="confirm-text font-weight-bold">If you delete this group note all of his group note will move to personal notes.<br></br><br></br> Are you sure want to delete this note ?</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="default" className="cancel-btn" onClick={() => {
                            this.setState({
                                // ConfirmationType: '',
                                showDeleteGroupNoteModal: false,
                                // noteContainMedia: false
                            })
                        }}>
                            Cancel
                        </Button>
                        <Button variant="default" className="delete-btn BR-8" onClick={() => { this.confirmationGroupNoteDelete() }}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>     
                {/* ----------------- Delete Group Note Modal -----------------*/}


                {/* -----------Modal For View Attachement-------- */}
                <Modal centered className="plays-video-modal br-8" show={this.state.showAttachement} >
                    <Modal.Header className="mediaHeader">
                        <div className="mediaHeaderBtns"
                            style={{ justifyContent: classPermission !== null && classPermission === 'read' && 'flex-end' }}
                        >
                            {
                                classPermission !== null && classPermission === 'read' ? '' :
                                    <span className="mediaArti">
                                        Mark As Artifact
                                        {/* {this.state.videoAttachment && this.state.videoAttachment.length > 0 && this.state.videoAttachment.map((val, i) => (<img src={closeIcon} alt='' />
                                ))} */}

                                        {this.state.videoAttachment && this.state.videoAttachment.length > 0 && this.state.videoAttachment.map((val, i) => (
                                            val.cc_attachment_id === this.state.showAttachementId ? <i id='ab' className={val.cc_media_mark_artified === "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} key={i} aria-hidden="true" onClick={() => { this.setMediaArtifact(val, i) }}></i> : ""
                                        ))}
                                    </span>
                            }

                            <button type="button" className="delete-btn" onClick={() => {
                                this.setState({
                                    showAttachement: false,
                                    showAttachementURL: "",
                                    showAttachementName: "",
                                    showAttachementId: "",
                                })
                            }}
                                style={{ width: 40, height: 40, borderRadius: 8, }}
                            >
                                <img src={closeIcon} alt='' />
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>

                        {this.state.showAttachementType !== 'audio' ?
                            <div>
                                {this.state.showAttachementType === 'image' ?
                                    <div>
                                        <Carousel title="Carousel" onSlide={this.onSlide} posImage={this.state.selectedMediaIndex !== 0 ? this.state.selectedMediaIndex : this.state.videoAttachment.length} totalAttachment={this.state.videoAttachment.length}>
                                            {this.state.videoAttachment && this.state.videoAttachment.length > 0 && this.state.videoAttachment.map(val => (

                                                val.cc_attachment_type === "image" ?
                                                    <Item img={val.cc_attachment_type === "image" && val.cc_attachment_fullurl} />
                                                    // <img src={val.cc_attachment_fullurl} />
                                                    : ""
                                            ))}
                                        </Carousel>
                                    </div>
                                    :
                                    <div>
                                        <video width="750" height="400" style={{ border: "12px solid #e9e9e9" }} controls src={this.state.showAttachementURL} ></video>
                                    </div>}
                            </div>
                            :
                            <div className="text-center">
                                <p>{this.state.showAttachementName}</p>
                                <audio controls className="player" preload="false" controlsList="nodownload" src={this.state.showAttachementURL} />
                            </div>}
                    </Modal.Body>
                </Modal>
                <Modal centered className="play-video-modal br-8" show={this.state.showDocAttachement} >
                    <Modal.Header className="mediaHeader">
                        <div className="mediaHeaderBtns"
                            style={{ justifyContent: classPermission !== null && classPermission === 'read' && 'flex-end' }}
                        >
                            {
                                classPermission !== null && classPermission === 'read' ? "" :
                                    <span className="docArti">Mark As Artifact
                                        {this.state.docAttachment && this.state.docAttachment.length > 0 && this.state.docAttachment.map((val, i) => (
                                            val.cc_attachment_id === this.state.showDocAttachementId ? <i className={val.cc_media_mark_artified == "yes" ? "fa fa-star starSelected" : "fa fa-star-o starUnSelected"} key={i} aria-hidden="true" onClick={() => { this.setDocArtifact(val, i) }}></i> : ""
                                        ))}
                                    </span>
                            }

                            <button type="button" variant="default" className="delete-btn" onClick={() => {
                                this.setState({
                                    showDocAttachement: false,
                                    showDocAttachementURL: "",
                                    showDocAttachementName: "",
                                    showDocAttachementId: "",
                                    selectedDocIndex: 0
                                })
                            }}
                                style={{ width: 40, height: 40, borderRadius: 8 }}
                            >
                                <img src={closeIcon} alt='' />
                            </button>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <IframeGoogleDoc url={this.state.showDocAttachementURL} />
                    </Modal.Body>
                </Modal>
                {/* -----------Modal For View Attachement-------- */}

                {this.state.showPopUp && <UpgradePopUp handleClosePopUp={this.handleClosePopUp} />}
            </div >
        )
    }
}

const mapStateToProps = state => ({
    state: state,
    notesData: state.studentNote,
    Notesloader: state.studentNote?.Notesloader,
    loader: state.studentNote?.loader
});

const mapDispatchToProps = dispatch => {
    return {
        getStudentClasses: (data) => dispatch(getStudentClasses(data)),
        deleteStudentNote: (data) => dispatch(deleteStudentNote(data)),
        getStudentNote: (data) => dispatch(getStudentNote(data)),
        setStudentArtifact: (data) => dispatch(setStudentArtifact(data)),
        addStudentMiscellaneous: (data) => dispatch(addStudentMiscellaneous(data)),
        editStudentMiscellaneous: (data) => dispatch(editStudentMiscellaneous(data)),
        deleteStudentMiscellaneous: (data) => dispatch(deleteStudentMiscellaneous(data)),
        getStudentGradebookNullRequest: (data) => dispatch(getStudentGradebookNullRequest(data)),
        setMediaAttachmentArtifact: (data) => dispatch(setMediaAttachmentArtifact(data)),
        deleteGroupNoteFromSingleStudent: (data) => dispatch(deleteGroupNoteFromSingleStudent(data))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(
    StudentNoteDashboard
);
