import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Logo from "Assets/CommonComponent/headerLogo.png";
import * as routes from "Router/RoutesURL";
import NotificationIndex from "Components/NotificationComponent/NotificationIndex";
import { getNotificationRequest } from "Actions/NotificationAction/notificationAction";
import { logOut } from "Utils/Helper";
import downArrow from "Assets/CommonComponent/downArrow.svg";
import notificationIcon from "Assets/CommonComponent/notificationIcon.png";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { getAcademicReset } from "Actions/AcademicAction/academicAction";
import "./Header.css";
import supportIcon from "Assets/CommonComponent/support.png";
import settingsIcon from "Assets/CommonComponent/settings.png";
import logoutIcon from "Assets/CommonComponent/logout.png";
import groupIcon from "Assets/CommonComponent/Group.png";
class header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      show: false,
      pageNo: 1,
      dropdownOpen: false,
    };
    this.ref = React.createRef();
  }

  componentDidMount() {

    document.addEventListener("mousedown", this.handleOnClickOutside);
    document.addEventListener("touchstart", this.handleOnClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleOnClickOutside);
    document.removeEventListener("touchstart", this.handleOnClickOutside);
  }

  handleOnClickOutside = (event) => {
    if (!this.ref.current || this.ref.current.contains(event.target)) {
      return;
    } else {
      this.setState({
        dropdownOpen: false,
      });
    }
  };

  UNSAFE_componentWillMount() {
    let apiData = {
      page_no: this.state.pageNo,
    };
    // console.log("dispatchedddd 2")
    // this.props.getNotificationRequest(apiData);
    // setInterval(() => {
    //         let apiData = {
    //             "page_no": this.state.pageNo,
    //        }
    //         this.props.getNotificationRequest(apiData)
    //   }, 10000);
  }
  setIsOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  goToHome = () => {
    // this.props.history.push('/home/academic');
    if(sessionStorage?.getItem("userHasFreePlan") != "0") {
        // sessionStorage.removeItem("selectedAcademicYear");
        // sessionStorage.removeItem("isSelectedYear");
        // sessionStorage.removeItem("selectedAcademicYearData");
        sessionStorage.removeItem("UserInitialLogin");
        this.props.getAcademicReset();
        this.props.history.replace("/home/academic");
      } else {
        this.props.history?.replace('/home/upgradePlan');
      }
   
  };

  goToDataUsage = () => {
    sessionStorage.removeItem("UserInitialLogin");
    this.props.history.push(routes.DATAUSAGE);
  };
  goToAbout = () => {
    this.props.history.push(routes.ABOUT);
  };
  openNotification = () => {
    window.$("#notificationModal").modal("show");

    this.setState({
      show: true,
    });
  };

  onOutside = () => {
    this.setState({
      show: false,
    });
  };
  goToSettings = () => {
    this.props.history.push(routes.SETTINGS);
  };
  goToContact = () => {
    this.props.history.push(routes.CONTACTUS);
  };

  goToLogin = () => {
    sessionStorage.clear();
    // window.location.reload()
    // window.location.href = routes.LOGIN;
    // createNotification('success', 'User logout successfully');
    logOut("success", "User logout successfully");
  };

  render() {
    const { isOpen, show } = this.state;
    const { history, getNotification } = this.props;
    return (
      <div className="bs-example">
        <Navbar light expand="md">
          <NavbarBrand>
            <img
              onClick={() => {
                this.goToHome();
              }}
              src={Logo}
              className="mainLogo"
              height="38"
              alt="CoolBrand"
            />
          </NavbarBrand>
          <NavbarToggler
            onClick={() => {
              this.setIsOpen();
            }}
          >
            <i className="menuIcon material-icons">menu</i>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <div className="navbar-nav ml-auto">
              {sessionStorage?.getItem("userHasFreePlan") != "0" && (
                <NavItem onClick={() => this.goToHome()}>
                  <NavLink className="fontletter">ACADEMIC YEAR</NavLink>
                </NavItem>
              )}
              {sessionStorage?.getItem("userHasFreePlan") != "0" && (
                <NavItem onClick={() => this.goToDataUsage()}>
                  <NavLink className="fontletter">DATA USAGE</NavLink>
                </NavItem>
              )}
              {/* <NavItem onClick={() => this.goToDataUsage()}>
                                <NavLink className='fontletter'>DATA USAGE</NavLink>
                            </NavItem> */}
              <UncontrolledDropdown nav inNavbar>
                <div ref={this.ref}>
                  <DropdownToggle
                    nav
                    className="fontletter"
                    onClick={() => {
                      // console.log(this?.ref?.current);
                      // this.toggle()
                      this.setState({
                        dropdownOpen: !this.state.dropdownOpen,
                      });
                    }}
                  >
                    ACCOUNT
                    <img
                      src={downArrow}
                      className="arrowIcon"
                      height="17"
                      alt="CoolBrand"
                      style={{
                        transform: this.state.dropdownOpen && "rotate(180deg)",
                      }}
                    />
                  </DropdownToggle>
                </div>
                <div>
                  <DropdownMenu right className="customDropDownContainer">
                    <DropdownItem
                      onClick={() => {
                        sessionStorage.removeItem("UserInitialLogin");
                        history.push(routes.USERPROFILE);
                      }}
                      className="customDropdownHover"
                    >
                      {/* <div className='displayFlex'>
                                            <i className="dropIcon material-icons">person </i><span className='dropListText'>PROFILE</span>
                                        </div> */}
                      <div className="displayFlex dropListText">
                        {/* <i className="dropIcon material-icons">power_settings_new</i><p className='dropListText'>SUPPORT</p> */}
                        <img
                          src={groupIcon}
                          className="dropIcon"
                          alt="CoolBrand"
                        />
                        Profile
                      </div>
                    </DropdownItem>
                    <DropdownItem divider className="dropdownDividerCustom" />
                    <DropdownItem
                      // onClick={() => this.goToLogin()}
                      onClick={() => {
                        sessionStorage.removeItem("UserInitialLogin");
                        window.location = "mailto:support@chroniclecloud.com";
                      }}
                      className="customDropdownHover"
                    >
                      <div className="displayFlex dropListText">
                        {/* <i className="dropIcon material-icons">power_settings_new</i><p className='dropListText'>SUPPORT</p> */}
                        <img
                          style={{
                            height: "auto",
                            position: "relative",
                            top: 1,
                            marginLeft: "24px",
                          }}
                          src={supportIcon}
                          className="dropIcon"
                          alt="CoolBrand"
                        />
                        Support
                      </div>
                    </DropdownItem>
                    <DropdownItem divider className="dropdownDividerCustom" />
                    <DropdownItem
                      onClick={() => {
                        sessionStorage.removeItem("UserInitialLogin");
                        history.push(routes.SETTINGS);
                      }}
                      className="customDropdownHover"
                    >
                      {/* <div className='displayFlex'>
                                        <span className='dropIcon'> <SettingsOutlinedIcon/></span>
                                        <span className='dropListText'>SETTINGS</span> */}
                      <div className="displayFlex dropListText">
                        {/* <span className='dropIcon'> <SettingsOutlinedIcon/></span>
                                        <span className='dropListText'>SETTINGS</span> */}
                        <img
                          style={{ marginLeft: "20px" }}
                          src={settingsIcon}
                          className="dropIcon"
                          alt="CoolBrand"
                        />
                        Settings
                      </div>
                    </DropdownItem>
                    <DropdownItem divider className="dropdownDividerCustom" />
                    <DropdownItem
                      onClick={() => this.goToLogin()}
                      style={{ color: "#FF0000" }}
                      className="customDropdownHover"
                    >
                      <div className="displayFlex dropListText logoutTextColor">
                        {/* <span className='dropIcon'> <SettingsOutlinedIcon/></span>
                                        <span className='dropListText'>SETTINGS</span> */}
                        <img
                          src={logoutIcon}
                          className="dropIcon"
                          alt="CoolBrand"
                        />
                        Sign Out
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </div>
              </UncontrolledDropdown>
              <NavItem className="notification">
                {/* <i className="notificationIcon material-icons" onClick={this.openNotification}>notifications_none</i> */}
                <img
                  src={notificationIcon}
                  className="notificationIcon"
                  alt="CoolBrand"
                  onClick={this.openNotification}
                />
                {getNotification && getNotification.data.total_count !== 0 ? (
                  <span className="badges">
                    <p className="badgeValue">
                      {getNotification.data.total_count}
                    </p>
                  </span>
                ) : (
                  ""
                )}
              </NavItem>
            </div>
          </Collapse>
        </Navbar>
        <div
          className="modal right fade addOfClass"
          id="notificationModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="notificationModal"
        >
          <NotificationIndex
            showModal={show}
            onOutside={this.onOutside}
            getNotification={getNotification && getNotification}
          />
        </div>

        {/* ---------------Modal Add Class------------ */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  state: state,
  getNotification: state.Notification.Get_Notification,
});
const mapDispatchToProps = (dispatch) => {
  return {
    getNotificationRequest: (data) => dispatch(getNotificationRequest(data)),
    getAcademicReset: (data) => dispatch(getAcademicReset(data)),
  };
};
header.propTypes = {
  getNotification: PropTypes.object,
  history: PropTypes.object,
};
export default connect(mapStateToProps, mapDispatchToProps)(header);
