import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Row } from "reactstrap";
import { login } from "Actions/AuthAction/loginAction";
import Logo from "Assets/LoginModule/mainLogo.png";
import "firebase/analytics";
import * as routes from "Router/RoutesURL";
import "../LoginComponent/LoginPage.scss";
import PaypalBtn from "react-paypal-checkout";
import Box from '@mui/material/Box';
import DivLoader from "Components/LoadingComponent/DivLoader";
import successPlanImg from "Assets/SubscriptionsModule/successPlanImg.png";
import { PAYPALCLIENT, PAYPALENV, CURRENCY, LOCALE, BASE_MICROSERVICE_URL, BASE_MICROSERVICE_URL_PLAN } from "Config/Constant";
import { createNotification } from "Config/notificationtoast";
import { setPaymentDataRequest } from "Actions/PayPalPaymentAction/payPalPaymentAction";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import axios from "axios";
import { decryptData, encryptData } from "Utils/Helper";

const style = {
  label: "pay",
  tagline: false,
  size: "medium",
  shape: "rect",
  color: "blue",
  width: 300,
};

const RegisterPaypal = (props) => {
  console.log(24, props);
  // const { item } = props?.location?.state;
  const dispatch = useDispatch();
  const [showPaypal, setShowPaypalCheck] = useState(false);
  const [registerPaymentSuccess, setRegisterPaymentSuccess] = useState(false);
  const [stripePaymentSuccess, setStripePaymentSuccess] = useState(false);
  let [message, setMessage] = React.useState("");
  let [success, setSuccess] = React.useState(false);
  let [sessionId, setSessionId] = React.useState("");

  const statdata = useSelector((state) => console.log(state));

  const ButtonWrapper = ({ type }) => {
    const [{ options }, dispatch] = usePayPalScriptReducer();

    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          intent: "subscription",
        },
      });
    }, [type]);

    return (
      <PayPalButtons
        createSubscription={async (data, actions) => {
          console.log(data);
          console.log(actions);
          try {
            const orderId = await actions.subscription
              .create({
                plan_id: props?.location?.state?.item?.payPalObj?.id,
              });
            console.log(orderId);
            return orderId;
          } catch (err) {
            console.log(err);
          }
        }}
        onApprove={function (data, actions) {
          console.log(data);
          console.log(actions);
          setStripePaymentSuccess(true);
          const userEmail = sessionStorage.getItem("RegisterEmail");
          const userPassword = sessionStorage.getItem("Password");
          const keepMeSinged = sessionStorage.getItem("keepMeSinged");
          const sendRequest = {
            user_email_id: userEmail,
            user_password: userPassword,
            keepMeSinged: keepMeSinged,
          };
          console.log("sendRequest", sendRequest);
    
          dispatch(login(sendRequest));
        }}
        style={{
          label: "subscribe",
          tagline: false,
          size: "medium",
          shape: "rect",
          color: "blue",
          // height: 45,
        }}
        className="newPaypalBtnClassRegister"
      />
    );
  };

  const paypalStatus = useSelector(
    (state) => state?.PayPalPayment?.paymentStatus
  );

console.log(98, paypalStatus , stripePaymentSuccess)

  useEffect(() => {
    setTimeout(function () {
      setShowPaypalCheck(true);
    }, 2000);
  }, []);

  
  useEffect(() => {
    console.log('another usffct blank useeffect&&&');
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    console.log('query&&& :>> ', query);
    console.log('query.get("success")&&& :>> ', query.get("success"));
  }, []);
  const querywithoutusffct = new URLSearchParams(window?.location?.search);
  console.log('querywithoutusffct&&& :>> ', querywithoutusffct);
  useEffect(() => {
    console.log('useffect func with querywithout useffect&&& :>> ', querywithoutusffct);
  }, [querywithoutusffct])
  console.log('stripepaymentsuccess&&& :>> ', stripePaymentSuccess);
  React.useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    console.log('query under sid usefct&&& :>> ', query);
    if (query.get("success")) {
      console.log('query.get("success") under sid effect&&&  :>> ', query.get("success"));
      setStripePaymentSuccess(true);
      setSuccess(true);
      setSessionId(query.get("session_id"));
      sessionStorage.setItem("sessionIdSaved", query.get("session_id"));
      const userEmail = sessionStorage.getItem("RegisterEmail");
      const userPassword = sessionStorage.getItem("Password");
      const keepMeSinged = sessionStorage.getItem("keepMeSinged");
      // setTimeout(() => {
        
        const sendRequest = {
          user_email_id: userEmail,
          user_password: userPassword,
          keepMeSinged: keepMeSinged,
        };
        console.log("sendRequest", sendRequest);
  
        dispatch(login(sendRequest));
      // }, 3000);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [sessionId]);

  useEffect(() => {
    console.log('paypalStatus || stripePaymentSuccess :>> ', paypalStatus || stripePaymentSuccess);
    if (paypalStatus || stripePaymentSuccess) {
      const userEmail = sessionStorage.getItem("RegisterEmail");
      const userPassword = sessionStorage.getItem("Password");
      const keepMeSinged = sessionStorage.getItem("keepMeSinged");
        
        const sendRequest = {
          user_email_id: userEmail,
          user_password: userPassword,
          keepMeSinged: keepMeSinged,
        };
        console.log("sendRequest", sendRequest);
  
        dispatch(login(sendRequest));
      }
  }, [paypalStatus, stripePaymentSuccess]);

  // -----On Payment Success------
  const onSuccess = (payment) => {
    const Data = {
      agreement_id: payment?.paymentID,
      plan_id:
        props?.location?.state?.item?.selectedSubType == "monthly" ||
        JSON.parse(sessionStorage.getItem("paymentObj"))?.selectedSubType ==
          "monthly"
          ? props?.location?.state?.item?.product_ids?.Web?.monthly ||
            JSON.parse(sessionStorage.getItem("paymentObj"))?.product_ids?.Web
              ?.monthly
          : props?.location?.state?.item?.product_ids?.Web?.yearly ||
            JSON.parse(sessionStorage.getItem("paymentObj"))?.product_ids?.Web
              ?.yearly,
      transaction_type: "PayPal",
      receipt_data: payment?.paymentToken,
      type: "web",
      email: payment?.email,
      payerID: payment?.payerID,
      paymentToken: payment?.paymentToken,
      subscription_json: payment?.address,
    };
    setRegisterPaymentSuccess(true);
    dispatch(setPaymentDataRequest(Data));
  };
  // -----On Payment Success------

  // -----On Payment Cancel------
  const onCancel = (data) => {
    createNotification("warning", "The payment is cancelled");
  };
  // -----On Payment Cancel------

  // -----On Payment Error------
  const onError = (err) => {
    createNotification("error", "Something went wrong");
  };
  // -----On Payment Error------
// *************************



// **************************
  let planContent =
    props?.location?.state?.item?.cc_subscription_content?.split("\\n") ||
    JSON.parse(
      sessionStorage.getItem("paymentObj")
    )?.cc_subscription_content?.split("\\n");

    const stripeCheckoutHandler = async (value) => {
      console.log(value , "value4555")
      let data = {
        lookup_key : value?.id,
        customer_email : JSON.parse(sessionStorage.getItem('UserData'))?.cc_user_email_id,
        isPreviousFreePlan: 0
      }
      const result = await axios.post(`${BASE_MICROSERVICE_URL_PLAN}subscription/create-checkout-session`, {data : encryptData(data)})
      console.log(result , "resultstripe555")
      const decryptedDataRes = decryptData(result?.data?.res)
      console.log("decryptdatares555",decryptedDataRes)
      window.open(decryptedDataRes?.data?.url, "_self")
    }

  return (
    <React.Fragment>
      <div className="LoginMainContainer">
        <div className="LoginSubContainer">
          <div data-testid='logo-div'>
            <img alt="logo" src={Logo} className="img-div" />
          </div>
        </div>
        <div className="LoginSubContainer">
          <div className="LoginCardDiv h-auto usersubsplanheight registerpaypalParentDiv" style={{marginTop : "1rem"}}>
            <>
            {
              console.log('paypalStatus :>> ', paypalStatus)
            }
            {  console.log('stripePaymentSuccess :>> ', stripePaymentSuccess)}
              {paypalStatus ||
                (stripePaymentSuccess && (
                  <>
                    <Col md={12} lg={12}>
                      <img
                        src={successPlanImg}
                        className="img-fluid"
                        alt="successs"
                        width={165}
                      />
                      <p className="subscriptionSuccessParaStyle mt-4 text-center">
                        Subscription Successful!
                      </p>
                    </Col>
                  </>
                ))}
                <Box my={2} display="flex" sx={{fontSize:  "0.9rem"}} alignItems={'end'}>
                <ArrowBackIosIcon sx={{fontSize : '20px', cursor:"pointer"}} onClick={() => {props?.history?.push(routes.USERSUBSCRIPTION)}}/> <span className='cursor-pointer' onClick={() => {props?.history?.push(routes.USERSUBSCRIPTION)}}>Back</span>
                </Box>
              <div className="planContainerDiv mb-3" data-testid='multiple-plans'>
                <Row>
                  <Col
                    lg={3}
                    md={3}
                    xs={12}
                    sm={12}
                    className="planIconBg d-flex flex-wrap align-items-center"
                  >
                    <img
                      src={
                        props?.location?.state?.item?.plan_logo ||
                        JSON.parse(sessionStorage.getItem("paymentObj"))
                          ?.plan_logo
                      }
                      className="img-fluid"
                      alt={props?.location?.state?.item?.plan_logo}
                      width={90}
                    />
                  </Col>
                  <Col
                    lg={9}
                    md={9}
                    xs={12}
                    sm={12}
                    className="m-auto position-relative"
                  >
                    <p className="dataPlans mb-0" dataPlans>
                      *{" "}
                      {props?.location?.state?.item
                        ?.cc_subscription_description ||
                        JSON.parse(sessionStorage.getItem("paymentObj"))
                          ?.cc_subscription_description}
                    </p>
                    {planContent?.map((planItem, PlanIdx) => {
                      return (
                        <>
                          <p className="dataPlans mb-0" dataPlans>
                            {planItem}
                          </p>
                        </>
                      );
                    })}
                    <div className="mt-3">
                      {props?.location?.state?.item?.cc_subscription_title ==
                        "Personal" ||
                      JSON.parse(sessionStorage.getItem("paymentObj"))
                        ?.cc_subscription_title == "Personal" ? (
                        <Button
                          variant="contained"
                          className="planFreeBtn float-left font-weight-bold"
                          disabled={true}
                        >
                          Free
                        </Button>
                      ) : (
                        <>
                          <div className="upgradePlanButtonRegsiter">
                            <p className="planPrice">{`$ ${
                              props?.location?.state?.item
                                .cc_subscription_price ||
                              JSON.parse(sessionStorage.getItem("paymentObj"))
                                ?.cc_subscription_price
                            } / month`}</p>
                          </div>
                          <div className="maxDetailsParaRegister">
                            <p className="annualPlanTypeParaRegister mt-0 mb-0">
                              {props?.location?.state?.item
                                ?.cc_subscription_annual_subTitle ||
                                JSON.parse(sessionStorage.getItem("paymentObj"))
                                  ?.cc_subscription_annual_subTitle}{" "}
                              &{" "}
                              <span>
                                {props?.location?.state?.item?.cc_subscription_annual_subTitle_discount?.slice(
                                  1,
                                  -1
                                ) ||
                                  JSON.parse(
                                    sessionStorage.getItem("paymentObj")
                                  )?.cc_subscription_annual_subTitle_discount?.slice(
                                    1,
                                    -1
                                  )}
                              </span>
                            </p>
                            <p
                              className="selectedAnnualPlanPriceRegsiter mt-0 mb-0"
                              // onClick={() => {
                              //   choosePlan(
                              //     item,
                              //     "annual",
                              //     item.cc_subscription_annual_price
                              //   );
                              // }}
                            >{`$ ${
                              props?.location?.state?.item
                                ?.cc_subscription_annual_price ||
                              JSON.parse(sessionStorage.getItem("paymentObj"))
                                ?.cc_subscription_annual_price
                            } / year`}</p>
                          </div>
                        </>
                      )}
                    </div>
                    <img
                      src={
                        props?.location?.state?.item?.plan_logo_bg ||
                        JSON.parse(sessionStorage.getItem("paymentObj"))
                          ?.plan_logo_bg
                      }
                      className="img-fluid"
                      alt={"plan_bg"}
                      style={{
                        position: "absolute",
                        bottom: "-13px",
                        right: 13,
                      }}
                    />
                    {(props?.location?.state?.item?.cc_subscription_title ===
                      "Premium" ||
                      JSON.parse(sessionStorage.getItem("paymentObj"))
                        ?.cc_subscription_title == "Premium") && (
                      <div className="recommendBoxRegister">Recommended</div>
                    )}
                  </Col>
                </Row>
              </div>

              {paypalStatus ||
                (stripePaymentSuccess && (
                  <>
                    <p className="loginRedirectPara">
                      please wait redirecting to login
                    </p>
                  </>
                ))}

              {
                // !paypalStatus ||
                !stripePaymentSuccess && (
                  <>
                    <Row>
                      <Col md={12} lg={12}>
                        {showPaypal ? (
                          <div className="d-flex justify-content-center">
                            {/* ------ Pay Button------ */}
                            <div className="stripePaymentDiv d-flex mr-2" style={{flexDirection : 'column'}}>
                              {/* <form
                                action={`https://stripe-server-beta.vercel.app/create-checkout-session`}
                                // action={`${BASE_MICROSERVICE_URL_PLAN}subscription/create-checkout-session`}
                                method="POST"
                              >
                                <input
                                  type="hidden"
                                  name="lookup_key"
                                  value={
                                    props?.item?.stripeObj?.id ||
                                    JSON.parse(
                                      sessionStorage.getItem("paymentObj")
                                    )?.stripeObj?.id
                                  }
                                /> */}
                               <Button
                                  onClick={() => {stripeCheckoutHandler(props?.item?.stripeObj?.id || JSON.parse(sessionStorage.getItem('paymentObj'))?.stripeObj)}}
                                  style={{ width: 250, height: "auto" }}
                                >
                                  Pay with <strong>Stripe</strong>
                                </Button>
                                <small style={{opacity:  "0.7", fontSize : '12px'}}><em><strong>(30-day trial)</strong></em></small>
                              {/* </form> */}
                            </div>
                            {/* <div className="paypalButtonDiv paypalRegisterButtonDiv">
                              <PaypalBtn
                                env={PAYPALENV}
                                client={PAYPALCLIENT}
                                currency={CURRENCY}
                                total={Number(
                                  props?.location?.state?.item
                                    ?.selectedSubType === "annual"
                                    ? props?.location?.state?.item
                                        ?.cc_subscription_annual_price
                                    : props?.location?.state?.item
                                        ?.cc_subscription_price
                                )}
                                locale={LOCALE}
                                style={style}
                                className="paypal-button-container"
                                onError={onError}
                                onSuccess={onSuccess}
                                onCancel={onCancel}
                              />
                              <small style={{opacity:  "0.7", fontSize : '12px'}}><em><strong>(One-Time Payment)</strong></em></small>
                         
                            </div> */}

                            {/* ------ Pay Button------ */}
                          </div>
                        ) : (
                          <div>
                            {" "}
                            <DivLoader />{" "}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} lg={12} className="pr-0 pl-0 mt-3">
                        <Card className="cardOfPlanRules">
                          <div className="planDetailsPoint planDetailsPointDashboardUpgrade">
                          <p className="dataPlans">
                              * Your PayPal account will be charged upon confirmation of the purchase.
                            </p>
                            <p className="dataPlans">
                              * The Stripe account offers you a 30-day trial period.
                            </p>
                            <p className="dataPlans">
                              * Subscription automatically renews unless
                              auto-renew is turned off at least 24 hours before
                              the end of the current period.
                            </p>
                            <p className="dataPlans">
                              * To turn off the auto-renewal, go to Paypal
                              Settings and make the necessary changes.
                            </p>
                            <p className="dataPlans">
                              *The account will identify the renewal cost and
                              charge the renewal fee within 24 hours before the
                              end of the current period.
                            </p>
                            <p className="dataPlans">
                              * In cases of an upgrade, the data will be added
                              to the upgraded plan.
                            </p>
                            <Row className="mt-5 pl-3 align-items-baseline">
                              <Col className="refCol" md={1.2} lg={1.2}>
                                <p className="refrenceLink">
                                  Reference Links :
                                </p>
                              </Col>

                              <Col md={9} lg={9}>
                                <a
                                  className="termsLink"
                                  href="https://www.chroniclecloud.com/terms"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  https://www.chroniclecloud.com/terms{" "}
                                </a>{" "}
                                <br></br>
                                <a
                                  href="https://www.chroniclecloud.com/faq"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {" "}
                                  https://www.chroniclecloud.com/faq
                                </a>
                              </Col>
                            </Row>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )
              }
            </>
          </div>
          <div className="bottomDiv">
            <p className="text-1">
              © 2022 All rights reserved | Built for Chronicle Cloud.
            </p>
            <p className="text-2">Powered with Synapse Xtreme Engine (SXE)</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RegisterPaypal;
